import SignaturesEnum from './signatureEnums';

export const isMimeTypePDF = base64 =>
  !!base64.includes(SignaturesEnum.PDF_FILE);

export const toImage = base64 =>
  base64 ? `data:image/png;base64,${base64}` : '';

export const base64ToBlob = base64 => {
  const byteCharacters = atob(base64);
  const len = byteCharacters.length;
  const byteArray = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }
  return new Blob([byteArray], { type: 'application/pdf' });
};

export function keyToName(key) {
  return (
    key
      .replace(/([A-Z])/g, ' $1')
      .replace(/m+\s/g, '')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export const toTitleCase = sentence =>
  sentence
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const keyToTitleCase = sentence =>
  toTitleCase(sentence.toLowerCase().replaceAll('_', ' '));

const mockedSessions = [
  'ZAAE950620MDFPRC02',
  'BOPJ830721HDFZQS02',
  'SOPM740805HDFLNR02',
  'LUPJ790816HDFJYN02',
  'RUNL710319HDFRVC02',
  'VENL810628HDFRKN02',
  'CANT921016HDFRNB02',
  'AOPL900622MDFLMS02',
  'VEPA680123MDFGLM02',
  'AAPG650423MDFLRZ02',
  'AOAA900808MDFLYL02',
  'MOND960616MDFRVL02',
  'RIPF651003MDFGRR02',
  'AIAA700715MDFRRP02',
  'TUAJ010926HDFRYSA5',
  'ZAPJ910316MDFPYS02',
  'CAPT700324MDFRPR02',
  'ZAAE001012MDFMRLA2',
  'VIAR880624HDFLYC02',
  'CANJ860514MDFPRN02',
  'TEPC761108MDFNCC02',
  'HEPL921016MDFRLL02',
  'ROAJ870617MDFMVR02',
  'SAAC730719HDFNZS02',
  'GUPM770512MDFZNR02',
  'AOPM770401HDFLQG02',
  'TUAW700614MDFRRL02',
  'VEAC000524HDFGRH02',
  'TEPS640828HDFJLR02',
  'MEPA990601MDFLSM02',
  'MIAM921016MDFGRR02',
  'TOPJ771009MDFRDZ02',
  'CAAJ960522HDFSYR02',
  'CAAA700722HDFRRD02',
  'DUAA910419MDFNRL02',
  'BAAA760726HDFYSD02',
  'OIAF770513MDFRSR02',
  'BAPM700327MDFCCY02',
  'RIPP850813MDFSNT02',
  'BEPM780227MDFJYR02',
  'ZAPD710902MDFPLD02',
  'GOAR700622MDFNRT02',
  'ROPN671125HDFDJH02',
  'HUAA891001HDFYZN02',
  'GOPE800815HDFNLD02',
  'CIPS800301HDFHRR02',
  'FOPR980402MDFLYS02',
  'BENM690920HDFDVR02',
  'MAPJ930523MDFMLL02',
  'MANR840424HDFRCY02',
  'ROPM950708MDFSDR02',
  'AOAS641226HDFLRR02',
  'MAPL010510MDFMRL02',
  'MINM700505MDFGCR02',
  'CIPC640802MDFHNL02',
  'ROAP870829MDFSRN02',
  'MOPM900603MDFRDR02',
  'COPM900906MDFRNL02',
  'GUNR030806HDFTSMA2',
  'MAPJ030730MDFLJSA2',
  'VENP030813MDFLVLA1',
  'AAPE030717HDFLDLA3',
  'AOPL030723HDFLMSA1',
  'ROAP030828MDFMRLA3',
  'MAPA030815MDFRLRA1',
  'MIPA030226MDFGVDA4',
  'GAPT030717HDFRRSA6',
  'DUPE030106HDFNRRA7',
  'MEAM030514HDFLYRA1',
  'VEPJ030307HDFRNNA2',
  'PAPS020926HDFRRNA2',
  'NUAJ020926HDFNSC06',
  'HONJ890307MDFRBD04',
  'EIPA691123HDFSRP06',
  'RIAM961018MDFSSR09',
  'PEDJ900816HVZNZL14',
  'GOAA900928HMCNLN04',
  'ROSA850810HJCDLN03',
  'MACI990101HOCRRR02',
  'AUAA900729MMCHRM08',
  'ROTB811215MMCSRN03',
  'ROTB881215MMCSRN03',
  'ROTB891215MMCSRN03',
  'ROTB821215MMCSRN03',
  'ROTB931215MMCSRN03',
  'ROTB921215MMCSRN03',
  'ROTB871215MMCSRN03',
  'ROTB861215MMCSRN03',
  'ROTB841215MMCSRN03',
  'ROTB961215MMCSRN03',
  'ROTB941215MMCSRN03',
  'PAPB631112HASRLN04',
  'CAAL621228HBCSZR05',
  'BAPC840913HBSCDR03',
  'BEPJ630212MCCDLS02',
  'AEPM931020MCSCSY04',
  'GANO841107MCHRCL06',
  'RIPF850427HCLQRL09',
  'GONE870328MCMNVV00',
  'AOAJ960626HDFLRL09',
  'GUPJ780408HDGTRC05',
  'AAPI771214HGTLRS03',
  'CIPE991013HGRHLD06',
  'BONH630817HMSZVR04',
  'GAAB961110MNLMRC09',
  'AEPJ890804HOCRXD00',
  'PIPL980723HHGNNS06',
  'OIPI860601HDGRRG00',
  'PAAF961110HCLRVY01',
  'BAPS921016MNTCUN04',
  'ROPJ990506MMCSXV09',
  'RIPF850427HDFGDR02',
  'MAAG720923HDFRYR03',
  'AOPD710415HGTLLG09',
  'CAAM880501MDGLLR08',
  'AOAA890702MCLLRS01',
  'GAPR961110MCSRGS04',
  'NUNJ761109HMCNVH00',
  'CAND010614HMCRRMA6',
  'ROPE761109MMCSRS09',
  'PIPM990803MMCNDR09',
  'SAAJ901021MMCNRS00',
  'BENN831206MMCJVN08',
  'CANT930110HMCRVB07',
  'AIPM030606MOCRNRA0',
  'FEAA800624MNLRSR05',
  'MAPJ030108HDGMLSA7',
  'ROPY890504HCSJCS06',
  'RUAC030413MDGZSLA7',
  'CAPF730531MNTRCL04',
  'PAPN901021MCLRLT04',
  'RUNM011109HCLZCRA6',
  'CANM621027MDFRVR02',
  'COPD010703MDFRLLA7',
  'CAPB931120MDFLYR00',
  'LAPG811109MDFNRN04',
  'BEPE910521MDFJRS04',
  'VIPD740707MDFLNR09',
  'LEAE990704MDFLYS09',
  'ROAS940820HDFMRR07',
  'MEPW771214HDFLLL07',
  'RUAF930417HDFZRR09',
  'MAPY710720HDFLRM05',
  'PAPL021225MCCRTCA8',
  'CAPY010827MJCHRRA7',
  'CANA950422HGTHGM00',
  'LANR961110HHGLVM07',
  'CANP771214HCSHTN09',
  'ZANE740101HBSPKD08',
  'AUPE910521MBSGNS08',
  'EIPP811211MCCSLD08',
  'AOPL940723HCCLRR09',
  'ROPJ010315HCCBLBA5',
  'SAPR781109HNTNDL09',
  'MAPU961110HNTLDR08',
  'RIPM030411MJCSJRA6',
  'FEAC961110MOCRSR02',
  'ZAAO000225MOCMRLA2',
  'BENN840831MNLJRN01',
  'CAPP921016HGRRRR08',
  'GANO870328HASMVC07',
  'BEPM800724MDFJDR05',
  'MAPE761109MDFLRL01',
  'CAPJ020901HDFHNVA9',
  'ROPH990619MCCBRL08',
  'BOPC810611HCCZRR03',
  'PAPM800106MCCRDR01',
  'GUPL870328HCCTLN01',
  'CAPE931120HSPRYL02',
  'DEAA910912MSPNRM04',
  'DETA761109MMNNJR05',
  'BEAA810828MASDBL07',
  'MEGA900101HDFDLL05',

  // VA
  '66f2df10de4538f5ef5758fc',
  '66f2b8c5e66541ad801a4437',
  '66f2b8adba741342eb6c7bb9',
  '66f2ad8271d3ccbc1b2eb5b9',
  '66f2ad67ba741342eb6c7590',
  '66f2ad4b71d3ccbc1b2eb4a7',
  '66f2ad30ba741342eb6c747f',
  '66f2ad1471d3ccbc1b2eb376',
  '66f2acf9ba741342eb6c735d',
  '66f2acdede4538f5ef574f32',
];

export const findInMockedSessions = curp => mockedSessions.includes(curp);
